.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #2A5298, #6E4DA2); /* Nice metallic blue to purple gradient */
  font-family: 'Comfortaa', sans-serif; /* Apply Comfortaa globally if you prefer */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

h1 {
  font-weight: 700; /* Comfortaa bold */
  color: white;
  margin: 20px;
  font-size: 4rem; /* Adjust size as necessary */
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3); /* Subtle shadow for depth */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
